import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/outline'
import { ChairOutlined, HandshakeOutlined } from '@mui/icons-material'
import { Container } from '@mui/material'
import { AgentProfiles } from 'components/home/sections/AgentProfiles'
import FaqSection from 'components/home/sections/FaqSection'
import { SellPageBanner } from 'components/home/SellPage'
import { NextPage } from 'next'
import Head from 'next/head'
import Link from 'next/link'
import { Button } from 'ui'

type IconCardProps = {
  children: React.ReactNode
  icon: React.ReactNode
}
const IconCard = (props: IconCardProps) => {
  return (
    <div className="relative mx-auto mt-12 flex max-w-sm flex-col items-center justify-center gap-2 rounded bg-white p-6 pt-14">
      <div className="absolute -top-12 left-1/2 -translate-x-1/2 transform rounded-full bg-brand-blue fill-white p-6">
        {props.icon}
      </div>
      {props.children}
    </div>
  )
}

const SellPage: NextPage = () => {
  return (
    <>
      <Head>
        <title>Sell and buy your home | HomeOptions</title>
      </Head>
      <SellPageBanner />
      <Container className="flex flex-col gap-4" sx={{ py: '6rem' }}>
        <h3 className="text-2xl font-semibold text-brand-blue">What to expect</h3>
        <div className="my-2 grid grid-cols-1 gap-10 md:grid-cols-3">
          <IconCard icon={<ChatBubbleOvalLeftEllipsisIcon className="h-12 w-12 text-white" />}>
            <h4 className="text-xl font-bold text-brand-blue">Share your needs</h4>
            <p className="text-brand-blue-900 font-body">
              {`Tell us the property you are looking to sell, as well as any preferences you have in mind for your agent.`}
            </p>
          </IconCard>
          <IconCard icon={<ChairOutlined className="h-12 w-12 fill-white" />}>
            <h4 className="text-xl font-bold text-brand-blue">Sit back and relax</h4>
            <p className="text-brand-blue-900 font-body">
              {`We match you with a top agent in your area that is tailored to your needs and preferences.`}
            </p>
          </IconCard>
          <IconCard icon={<HandshakeOutlined className="h-12 w-12 fill-white" />}>
            <h4 className="text-xl font-bold text-brand-blue">Meet your agent</h4>
            <p className="text-brand-blue-900 font-body">
              {`Get connected to your perfect agent to guide you through your transaction and get the most out of your property.`}
            </p>
          </IconCard>
        </div>
      </Container>
      <div className="bg-white">
        <Container className="flex flex-col gap-8 py-24">
          <h2 className="text-3xl font-bold tracking-tight text-brand-blue">
            Our Licensed Real Estate Agents
          </h2>
          <AgentProfiles />
        </Container>
      </div>
      <Container className="flex flex-col gap-4 py-10">
        <FaqSection type="agent" />
        <Link href="/sell/options">
          <Button variant="contained" color="secondary" className="mx-auto px-16">
            Get started today
          </Button>
        </Link>
      </Container>
    </>
  )
}

export default SellPage
